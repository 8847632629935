import axios from "axios";

// staging api url
// let BASE_URL = 'https://signableapi.disctesting.in/api'
// production api server
let BASE_URL = 'https://prodapi.signable.live/api'
// testing api url
// let BASE_URL = 'https://signabledevapi.disctesting.in/api'

//weburl for production
let webUrl = "https://helpline.signable.live"
//weburl for staging
// let webUrl = "https://signablehelpline.disctesting.in"

export const mainURL = BASE_URL
export const WEB_URL = webUrl

export const getDataFromApi = (getUrl) => {
    getUrl = getUrl.indexOf("mocky") !== -1 ? getUrl : (getUrl.startsWith("/") ? `${BASE_URL}${getUrl}` : `${BASE_URL}/${getUrl}`);
    const resp = axios.get(getUrl)
        .then((res) => {
            return res;
        })
        .catch((err) => {
            console.log("Error", err);
        });
    return resp;
};


// call the api (PUT) endpoint internal system
export const putDataFromApi = (putUrl, model) => {
    putUrl = putUrl.startsWith("/") ? `${BASE_URL}${putUrl}` : `${BASE_URL}/${putUrl}`;
    const resp = axios.put(putUrl, model)
        .then((res) => {
            return res;
        })
        .catch((err) => {
            console.log("Error", err);
        });
    return resp;
};
export const patchDataFromApi = (patchUrl, model) => {
    patchUrl = patchUrl.startsWith("/") ? `${BASE_URL}${patchUrl}` : `${BASE_URL}/${patchUrl}`;
    const resp = axios.patch(patchUrl, model)
        .then((res) => {
            return res;
        })
        .catch((err) => {
            console.log("Error", err);
        });
    return resp;
};

// call the api (POST) endpoint internal system
export const postDataFromApi = async (postUrl, model) => {
    postUrl = postUrl.startsWith("/") ? `${BASE_URL}${postUrl}` : `${BASE_URL}/${postUrl}`;
    const resp = await axios.post(postUrl, model)
        .then((res) => {
            return res;
        })
        .catch((err) => {
            // (resp.data.message)

            //    return err.response.data
            return err
            //    return err.response
        });
    return resp;
};

// call the api (DELETE) endpoint internal system
export const deleteDataFromApi = (deleteUrl, model) => {
    deleteUrl = deleteUrl.startsWith("/") ? `${BASE_URL}${deleteUrl}` : `${BASE_URL}/${deleteUrl}`;
    const resp = axios.delete(deleteUrl, { data: model })
        .then((res) => {
            return res;
        })
        .catch((err) => {
            console.log("Error", err);
        });
    return resp;
};